.App {
  font-family: "Roboto";
  text-align: center;
}
.ui.modal > .content {
  padding: 0;
}
.form {
  width: 100%;
}
.form-content {
  padding: 20px;
}
.form-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.7;
  z-index: 1;
}
.input-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.input {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.label {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}
.container-logo {
  display: flex;
  justify-content: center;
}
.logo {
  padding: 30px;
  width: 270px;
}
.button {
  width: 200px;
  margin-top: 10px;
}
.content {
  background-color: #ff7a64 !important;
}
.successMessage {
  position: absolute;
  bottom: 10px;
  padding: 15px;
  font-weight: 100;
  background-color: #5fb709;
  color: white;
  width: 500px;
  border-radius: 10px;
  z-index: 2;
}

.errorMessage {
  position: absolute;
  bottom: 10px;
  padding: 15px;
  width: 500px;
  font-weight: 100;
  background-color: #e4e7e6;
  color: #072b31;
  border-radius: 10px;
  z-index: 2;
}
.powered {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
}
.link {
  text-decoration: underline;
  margin-top: 5px;
  color: #0091ff;
}
.password {
  margin-bottom: 5px;
}
.closeButton {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 16px;
  cursor: pointer;
}
.logo-heru {
}
